// Generated by Framer (7f988a2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mNk0G0IF5", "TFNb8FYnE", "BnZ5HzNdh", "rt3WNV3RX", "uFJ6tNUFj", "OFqWF_3sV", "QWAi1HcIG", "IbprBHhkR", "uRrvOmLA0", "xgWwrGMh3", "FJ_saS2La", "l6UwDWptF"];

const serializationHash = "framer-bUQUJ"

const variantClassNames = {BnZ5HzNdh: "framer-v-e2w7bf", FJ_saS2La: "framer-v-1mwe4b8", IbprBHhkR: "framer-v-1a4jmey", l6UwDWptF: "framer-v-hdfpjv", mNk0G0IF5: "framer-v-kkn48n", OFqWF_3sV: "framer-v-sl9g6j", QWAi1HcIG: "framer-v-tljw8z", rt3WNV3RX: "framer-v-1iksmuw", TFNb8FYnE: "framer-v-10bm16g", uFJ6tNUFj: "framer-v-egsn6v", uRrvOmLA0: "framer-v-3lhcs2", xgWwrGMh3: "framer-v-1pj1q5q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Facing Left - Wide Angle": "uFJ6tNUFj", "Facing Left": "TFNb8FYnE", "Facing Right - Wide Angle": "rt3WNV3RX", "Facing Right": "BnZ5HzNdh", "Front - No Island": "OFqWF_3sV", "Left - No Island": "uRrvOmLA0", "Right - No Island": "xgWwrGMh3", "Variant 11": "QWAi1HcIG", "Variant 12": "IbprBHhkR", "Wide L - No Island": "l6UwDWptF", "Wide R - No Island": "FJ_saS2La", Front: "mNk0G0IF5"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mNk0G0IF5"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mNk0G0IF5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1j5evl4 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("QWAi1HcIG"), 2000)
})

const onAppear1uma3fd = activeVariantCallback(async (...args) => {
await delay(() => setVariant("OFqWF_3sV"), 2000)
})

useOnVariantChange(baseVariant, {IbprBHhkR: onAppear1uma3fd, OFqWF_3sV: onAppear1j5evl4, QWAi1HcIG: onAppear1uma3fd})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({OFqWF_3sV: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-kkn48n", className, classNames)} data-framer-name={"Front"} layoutDependency={layoutDependency} layoutId={"mNk0G0IF5"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({BnZ5HzNdh: {"data-framer-name": "Facing Right", background: {alt: "", positionX: "center", positionY: "center"}}, FJ_saS2La: {"data-framer-name": "Wide R - No Island", background: {alt: "", positionX: "center", positionY: "center"}}, IbprBHhkR: {"data-framer-name": "Variant 12", "data-highlight": true}, l6UwDWptF: {"data-framer-name": "Wide L - No Island", background: {alt: "", positionX: "center", positionY: "center"}}, OFqWF_3sV: {"data-framer-name": "Front - No Island", "data-highlight": true}, QWAi1HcIG: {"data-framer-name": "Variant 11", "data-highlight": true}, rt3WNV3RX: {"data-framer-name": "Facing Right - Wide Angle", background: {alt: "", positionX: "center", positionY: "center"}}, TFNb8FYnE: {"data-framer-name": "Facing Left", background: {alt: "", positionX: "center", positionY: "center"}}, uFJ6tNUFj: {"data-framer-name": "Facing Left - Wide Angle", background: {alt: "", positionX: "center", positionY: "center"}}, uRrvOmLA0: {"data-framer-name": "Left - No Island", background: {alt: "", positionX: "center", positionY: "center"}}, xgWwrGMh3: {"data-framer-name": "Right - No Island", background: {alt: "", positionX: "center", positionY: "center"}}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bUQUJ.framer-1uieahi, .framer-bUQUJ .framer-1uieahi { display: block; }", ".framer-bUQUJ.framer-kkn48n { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 772px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 400px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bUQUJ.framer-kkn48n { gap: 0px; } .framer-bUQUJ.framer-kkn48n > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-bUQUJ.framer-kkn48n > :first-child { margin-left: 0px; } .framer-bUQUJ.framer-kkn48n > :last-child { margin-right: 0px; } }", ".framer-bUQUJ.framer-v-10bm16g.framer-kkn48n, .framer-bUQUJ.framer-v-e2w7bf.framer-kkn48n, .framer-bUQUJ.framer-v-1iksmuw.framer-kkn48n, .framer-bUQUJ.framer-v-egsn6v.framer-kkn48n, .framer-bUQUJ.framer-v-sl9g6j.framer-kkn48n, .framer-bUQUJ.framer-v-tljw8z.framer-kkn48n, .framer-bUQUJ.framer-v-1a4jmey.framer-kkn48n, .framer-bUQUJ.framer-v-3lhcs2.framer-kkn48n, .framer-bUQUJ.framer-v-1pj1q5q.framer-kkn48n, .framer-bUQUJ.framer-v-1mwe4b8.framer-kkn48n, .framer-bUQUJ.framer-v-hdfpjv.framer-kkn48n { aspect-ratio: 0.5181347150259067 / 1; height: var(--framer-aspect-ratio-supported, 772px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 772
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"TFNb8FYnE":{"layout":["fixed","fixed"]},"BnZ5HzNdh":{"layout":["fixed","fixed"]},"rt3WNV3RX":{"layout":["fixed","fixed"]},"uFJ6tNUFj":{"layout":["fixed","fixed"]},"OFqWF_3sV":{"layout":["fixed","fixed"]},"QWAi1HcIG":{"layout":["fixed","fixed"]},"IbprBHhkR":{"layout":["fixed","fixed"]},"uRrvOmLA0":{"layout":["fixed","fixed"]},"xgWwrGMh3":{"layout":["fixed","fixed"]},"FJ_saS2La":{"layout":["fixed","fixed"]},"l6UwDWptF":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramernGEUnXcpb: React.ComponentType<Props> = withCSS(Component, css, "framer-bUQUJ") as typeof Component;
export default FramernGEUnXcpb;

FramernGEUnXcpb.displayName = "Device Copy";

FramernGEUnXcpb.defaultProps = {height: 772, width: 400};

addPropertyControls(FramernGEUnXcpb, {variant: {options: ["mNk0G0IF5", "TFNb8FYnE", "BnZ5HzNdh", "rt3WNV3RX", "uFJ6tNUFj", "OFqWF_3sV", "QWAi1HcIG", "IbprBHhkR", "uRrvOmLA0", "xgWwrGMh3", "FJ_saS2La", "l6UwDWptF"], optionTitles: ["Front", "Facing Left", "Facing Right", "Facing Right - Wide Angle", "Facing Left - Wide Angle", "Front - No Island", "Variant 11", "Variant 12", "Left - No Island", "Right - No Island", "Wide R - No Island", "Wide L - No Island"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramernGEUnXcpb, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})